import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import _ from "lodash";

import DropZone from "./main/DropZone";
import "./Home.css";
import { useAuth0 } from "@auth0/auth0-react";
import {
  isKaliberAdminOrDoctor,
  setAuthToken,
  setUserId,
} from "./utilities/authentication/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

const Home = () => {
  const {
    user,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [idToken, setIdToken] = React.useState("");
  const [accessToken, setAccessToken] = React.useState("");
  const [s3BucketName, setS3BucketName] = React.useState("sme-media");
  const [smeS3BucketName, setSMES3BucketName] = React.useState("sme-media");
  const [dicomS3BucketName, setDicomS3BucketName] =
    React.useState("dicom-media-assets");
  const [threeDS3BucketName, setThreeDS3BucketName] =
    React.useState("3d-media");
  const [medusaMediaS3BucketName, setMedusaMediaS3BucketName] =
    React.useState("medusa-media");

  React.useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        setAuthToken(accessToken);
        setAccessToken(accessToken);
        setUserId(user.sub.replace("auth0|", ""));
      } catch (e) {
        if (e.error === "consent_required") {
          const accessToken = await getAccessTokenWithPopup({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          });
          setAuthToken(accessToken);
          setAccessToken(accessToken);
          setUserId(user.sub.replace("auth0|", ""));
        } else {
          console.log(e.message); //TODO Add an error page so that user will know that the app has crashed
        }
      }
    };
    getAccessToken();
  }, [isAuthenticated]);

  const setIDToken = () => {
    if (_.isEmpty(user)) {
      return;
    }
    getIdTokenClaims().then((idToken) => setIdToken(idToken));
  };

  React.useEffect(setIDToken, [user]);

  React.useEffect(() => {
    const authDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";

    if (authDomain.includes("dev")) {
      setS3BucketName("sme-media-dev");
      setMedusaMediaS3BucketName("medusa-media-dev");
      setSMES3BucketName("sme-media-dev");
      setDicomS3BucketName("dicom-media-assets-dev");
      setThreeDS3BucketName("3d-media-dev");
    } else if (authDomain.includes("stage")) {
      setS3BucketName("sme-media-stage");
      setMedusaMediaS3BucketName("medusa-media-stage");
      setSMES3BucketName("sme-media-stage");
      setDicomS3BucketName("dicom-media-assets-stage");
      setThreeDS3BucketName("3d-media-stage");
    } else if (authDomain.includes("demo")) {
      setS3BucketName("sme-media-demo");
      setMedusaMediaS3BucketName("medusa-media-demo");
      setSMES3BucketName("sme-media-demo");
      setDicomS3BucketName("dicom-media-assets-demo");
      setThreeDS3BucketName("3d-media-demo");
    }
  }, []);

  const handleZipOrCSVOrDicomFileDropped = () => {
    const authDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";

    if (authDomain.includes("demo")) {
      setS3BucketName("dicom-media-assets-demo");
    } else if (authDomain.includes("dev")) {
      setS3BucketName("dicom-media-assets-dev");
    } else if (authDomain.includes("stage")) {
      setS3BucketName("dicom-media-assets-stage");
    } else {
      setS3BucketName("dicom-media-assets");
    }
  };

  const handle3DFileDropped = () => {
    const authDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
    if (authDomain.includes("demo")) {
      setS3BucketName("3d-media-demo");
    } else if (authDomain.includes("dev")) {
      setS3BucketName("3d-media-dev");
    } else if (authDomain.includes("stage")) {
      setS3BucketName("3d-media-stage");
    } else {
      setS3BucketName("3d-media");
    }
  };

  return (
    <div className="box custom-container">
      <div className="logo-container">
        <div className="logo-area">
          <a href="/">
            <img src="/KaliberAI-logo.png" id="logo" alt="Kaliber.ai" />
          </a>
        </div>
        <div className="right-container">
          <div className="s3-bucket-info-container">
            Select S3 Bucket for Upload
            <DropdownButton
              id="dropdown-basic-button"
              title={s3BucketName}
              alignRight
              variant="secondary"
              size="sm"
            >
              <Dropdown.Item onClick={() => setS3BucketName(smeS3BucketName)}>
                {smeS3BucketName}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setS3BucketName(dicomS3BucketName)}>
                {dicomS3BucketName}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setS3BucketName(threeDS3BucketName)}
              >
                {threeDS3BucketName}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setS3BucketName(medusaMediaS3BucketName)}
              >
                {medusaMediaS3BucketName}
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="user-info-container">
            Welcome back!
            <DropdownButton
              id="dropdown-basic-button"
              title={user.email}
              alignRight
              variant="secondary"
              size="sm"
            >
              <Dropdown.Item
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <i className="fas fa-sign-out-alt"></i> Log out
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      {isKaliberAdminOrDoctor(accessToken) ? (
        <DropZone
          user={user}
          idToken={idToken}
          s3BucketName={s3BucketName}
          onZipOrCSVOrDicomFileDropped={handleZipOrCSVOrDicomFileDropped}
          on3DFileDropped={handle3DFileDropped}
        />
      ) : (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Home;
